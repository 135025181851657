<template>
  <div>
    <v-list-item>
      <v-list-item-title class="pointer" @click.stop="openConsultation = true">{{ $t("Consultations") }}</v-list-item-title>
    </v-list-item>
    <v-dialog v-model="openConsultation" relative>
      <div class="mx-2 my-1">
        <v-btn color="red lighten-1" fab x-small dark @click.stop="openConsultation=false" top right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <consultation-table :request="request" :documents="consultDoc"></consultation-table>
    </v-dialog>
  </div>
</template>

<script>
import ConsultationTable from "@/components/consultation/ConsultationTable";
import { mapActions } from "vuex";
export default {
  name: "consultation",
  props:['request','consultDoc'],
  components:{
    ConsultationTable
  },
  created() {
    this.retrieveConsultations();
  },
  data() {
    return {
      openConsultation: false
    }
  },
  methods:{
    ...mapActions('consultation',['retrieveConsultations'])
  }
}
</script>

<style scoped>
.pointer{
  cursor:pointer;
}
</style>