<template>
  <v-container
    v-if="extension"
    fluid
    fill-height
    @mousedown="startDrawingBox"
    @mousemove="changeBox"
    @mouseup="stopDrawingBox"
    :class="isNote?'crosshair':''"
  >
   <div class="pdfFixed">
    <div class="fixed" ref="myPdfComponent">
    <clause-form
      v-model="openClauseForm"
      @input="cancelDraw"
      :drawingBox="drawingBox"
      :docBoxes="docBoxes"
      :editedIndex="editedIndex"
      @close="closeClauseForm"
    />
    <drawer
      v-if="isDrawing"
      :b-width="drawingBox.width"
      :b-height="drawingBox.height"
      :b-top="drawingBox.top"
      :b-left="drawingBox.left"
      :color="drawingBox.color"
    />
    <PDFPage
      v-if="pagePdf !== null"
      :page=pagePdf[page-1]
      :scale=scale
      :pageNumber=page
      :key="'a'+page">
    </PDFPage>
    <drawer
      v-for="box in boxes"
      :key="box.id"
      @editBox="editBox"
      :id="box.id"
      :b-top="box.top"
      :b-left="box.left"
      :b-width="box.width"
      :b-height="box.height"
      :color="box.color"
    ></drawer>
    <notes v-if="sticky.active" :n-height="sticky.height" :n-left="sticky.left" :n-top="sticky.top" :n-width="sticky.width" :key="'b'+0" @updateContent="updateContent"></notes>
    <notes :id="note.id" :n-height="note.height" :n-left="note.left" :n-top="note.top" :n-width="note.width" :n-content="note.content" v-for="(note) in notes" :key="'b'+note.id" @updateContent="updateContent"></notes>
    </div>
   </div>
   <v-progress-circular
      v-if="loading"
      :size="70"
      indeterminate
      color="red"
    ></v-progress-circular>
  </v-container>
</template>

<script>
//import pdf from "vue-pdf";
import { mapGetters, mapState, mapActions } from "vuex";
import Drawer from "../Drawer";
import { pick } from "lodash";
import ClauseForm from "../ClauseForm";
import PDFPage from "./PDFPage";
import Notes from "@/components/Notes";

const getCoursorLeft = e => {
  return e.offsetX;
};

const getCoursorTop = e => {
  return e.offsetY;
};

export default {
  components: {
    Notes,
    //pdf,
    Drawer,
    ClauseForm,
    PDFPage
  },

  data() {
    return {
      drawingBox: {
        active: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        color: "#23707696",
        clausesUsed: [],
        rationale: ""
      },
      sticky:{
        active:false,
        top:0,
        left:0,
        height:0,
        width:0,
        content:0
      },
      docBoxes: [],
      editedIndex: -1,
      openClauseForm: false,
      pages: null,
      test:null
    };
  },

  computed: {
    ...mapGetters("documents", {
      //getPreview: "getPreview",
      boxes: "getBoxesByPage",
      notes: "getNotesByPage"
    }),
    ...mapState("documents", {
      page: "currentPage",
      isDrawing: "isDrawing",
      isNote: "isNote",
      markupType: "markupType",
      numPages: "numPages",
      extension: "extension",
      document: "document",
      //preview: "preview",
      pagePdf: "pagePdf",
      loading:"loading",
      scale:"scale",
      pageStatus:"pageStatus",

    }),
    ...mapState('request',{
      requestItem: "requestItem"
    })
  },
  created(){
    this.$store.dispatch("configuration/retriveConfigurations");
    this.$store.dispatch("consultation/retrieveConsultations");
    this.$store.dispatch("address/retrieveAddresses");
    this.getNotesByPage({request_id: this.requestItem.id, doc_id: this.document.id, page_num: this.page })
  },
  watch:{
    page(){
      this.getNotesByPage({doc_id: this.document.id, page_num: this.page});
    }
  },
  mounted() {
    this.$root.$on("PDFEditModePage", () => {
      this.editedIndex = -1;
      this.setWithHeldPage();
    });
    this.$root.$on("PDFEditModeDocument", () => {
      this.editedIndex = -1;
      this.setWithHeldDocument();
    });
    this.$root.$on("PDFEditModeRectangle", () => {
      this.editedIndex = -1;
      this.setWithHeldRectangle();
    });
    this.$root.$on("PDFEditModeHighlights", () => {
      this.editedIndex = -1;
      this.setWithHeldHighlights();
    });
  },

  methods: {
    addContent(data){
      data.request_id = this.requestItem.id;
      data.page_num = this.page;
      data.doc_id = this.document.id;
      this.setNotesByPage(data);
    },
    updateContent(data){
      data.request_id = this.requestItem.id;
      data.page_num = this.page;
      data.doc_id = this.document.id;
      this.updateNotesByPage(data);
    },
    cancelDraw() {
      this.retrieveDocumentInfo(this.document.id);
      this.drawingBox = {
        active: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        color: "#23707696",
        clausesUsed: [],
        rationale: ""
      }
    },

    startDrawingBox(e) {
      if(this.markupType === "Rectangle"){
        if (this.isDrawing) {
          this.drawingBox = {
            width: 0,
            height: 0,
            top: getCoursorTop(e),
            left: getCoursorLeft(e),
            active: true,
            color: "#23707696",
            clausesUsed: [],
            rationale: ""
          };
        }
      }
      if(this.markupType === "Highlights"){
        if( this.isNote){
          this.sticky = {
            width: 0,
            height: 0,
            top: getCoursorTop(e),
            left: getCoursorLeft(e),
            content: "",
            active:true
          };
        }
      }
    },

    changeBox(e) {
      if (this.drawingBox.active) {
        this.drawingBox = {
          ...this.drawingBox,
          width: getCoursorLeft(e) - this.drawingBox.left,
          height: getCoursorTop(e) - this.drawingBox.top
        };
      }
      if(this.markupType === "Highlights"){
        if(this.sticky.active){
          this.sticky = {
            ...this.sticky,
            width: getCoursorLeft(e) - this.sticky.left,
            height: getCoursorTop(e) - this.sticky.top
          };
        }
      }
    },

    async stopDrawingBox() {
      if(this.markupType === "Rectangle") {
        if (this.isDrawing) {
          if (this.drawingBox.width > 5) {
            this.boxes.push({
              ...pick(this.drawingBox, [
                "width",
                "height",
                "top",
                "left",
                "color"
              ])
            });
            this.openClauseForm = true;
          }
        }
        this.drawingBox.active = false;
        this.setIsDrawing(false);
      }
      if(this.markupType === "Highlights"){
        if (this.isNote) {
          if (this.sticky.width > 5) {
            let pageStatus = this.sticky;
            pageStatus.request_id = this.requestItem.id;
            pageStatus.doc_id = this.document.id;
            pageStatus.page_num = this.page;
            if(pageStatus){
              await this.setNotesByPage(pageStatus);
            }
          }
        }
        this.sticky.active = false;
        this.setIsNote(false);
      }
    },
    closeClauseForm() {
      this.openClauseForm = false;
      this.setIsDrawing(false);
      this.boxes.pop();
      this.drawingBox = {
        active: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        color: "#23707696",
        clausesUsed: [],
        rationale: ""
      };
    },

    setWithHeldRectangle() {
      this.setIsDrawing(true);
      this.setMarkupType('Rectangle');
      this.drawingBox = {
        active: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        color: "#23707696",
        clausesUsed: [],
        rationale: ""
      };
    },
    setWithHeldHighlights() {
      this.setIsNote(true);
      this.setMarkupType('Highlights');
      this.sticky = {
        top: 0,
        left: 0,
        height: 0,
        width:0,
        content:''
      }
    },
    setWithHeldPage() {
      this.setMarkupType('Page');
      const canvasHeitht = this.$refs.myPdfComponent.clientHeight;
      this.drawingBox = {
          active: false,
          height: canvasHeitht,
          width: 800,
          top: 0,
          left: 0,
          page: this.page,
          color: "#23707696",
          clausesUsed: [],
          rationale: ""
        };
        this.boxes.push({
          ...pick(this.drawingBox, ["width", "height", "top", "left", "color"])
        });
        this.openClauseForm = true;
    },
    setWithHeldDocument() {
      this.setMarkupType('Document');
      const canvasHeitht = this.$refs.myPdfComponent.clientHeight;
        for (let index = 1; index <= this.numPages; index++) {
          this.drawingBox = {
            active: false,
            height: canvasHeitht,
            width: 800,
            top: 0,
            left: 0,
            pageNum: index,
            color: "#23707696",
            clausesUsed: [],
            rationale: ""
          };
          this.docBoxes.push({
            ...pick(this.drawingBox, [
              "width",
              "height",
              "top",
              "left",
              "color",
              "pageNum"
            ])
          });
      }
      this.openClauseForm = true;
    },

    editBox(id) {
      this.drawingBox = this.boxes.find(x => x.id == id);
      this.editedIndex = this.boxes.findIndex(item => item.id == id);
      this.openClauseForm = true;
    },

    ...mapActions("documents", ["updateNotesByPage","setNotesByPage","getNotesByPage","setIsDrawing","setIsNote", 'setMarkupType', 'retrieveDocumentInfo','addPageStatus','setPageStatus'])
  }
};
</script>

<style lang="scss">
  .crosshair{
    cursor:crosshair;
  }
.v-progress-circular{
    left: 50%;
    top: 50%;
    position: fixed;
    z-index: 9;
}
.pdfFixed {
  width: 800px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
// .fixed > span > span{
//   transform: scale(1) !important;
// }
.fixed {
  width: 800px;
  position: fixed;
  /* Do not set top / left! */
}
</style>
