<template>
    <div v-if="warning">
        <v-alert
        outlined
        dense
        type="warning"
        border="left"
        dismissible
      >
        Your session is about to expire.
        You will be logged out in 60 seconds.
      </v-alert>
    </div>
</template>

<script>
export default {
    name: "AutoLogout",

    data() {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'load'],
            warningTimer: null,
            logoutTimer: null,
            warning: false
        }
    },

    mounted() {
        this.events.forEach(event => {
            window.addEventListener(event, this.resetTimer)
        }, this);

        this.setTimers();
    },

    destroyed() {
        this.events.forEach(event => {
            window.removeEventListener(event, this.resetTimer)
        }, this)

        this.resetTimer();
    },

    methods: {
        setTimers() {
            this.warningTimer = setTimeout(this.warningMessage, 30 * 60 * 1000);
            this.logoutTimer = setTimeout(this.logout, 32 * 60 * 1000);
            this.warning = false;
        }, 

        warningMessage() {
            this.warning = true;
        },

        resetTimer() {
            clearTimeout(this.warningTimer);
            clearTimeout(this.logoutTimer);
            this.setTimers();
        },

        logout() {
            this.$store.dispatch("destroyToken").then(() => {
                this.$router.push({ name: "login" }).catch((  )=>{});
                // eslint-disable-next-line no-console
            });
        },
    }
}
</script>