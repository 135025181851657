<template>
  <div class="notes-wrapper">
      <div class="edit-note"
           v-if="!minimize"
           :style="{
         top: nTop + 'px',
          left: nLeft + 'px',
          width: nWidth + 'px',
          height: nHeight + 'px'}">
        <textarea v-if="enable" :readonly="!enable" border="1" :id="'note-text-'+id" :style="{
         top: (nTop+10) + 'px',
          left: nLeft + 'px',
          width: nWidth + 'px',
          height: nHeight+ 'px'}"
                  height="100%" v-model="content" :placeholder="$t('addnote')" @change="updateContent()">
        </textarea>
        <span v-else>{{content}}</span>
        <v-icon class="ml-auto clickable close-btn" @click="deleteNote(id)">mdi-close</v-icon>
        <v-icon v-if="enable" class="ml-auto clickable minus-btn" @click="enable = false, content = ''">mdi-eye</v-icon>
        <v-icon v-else class="ml-auto clickable minus-btn" @click="enable = true, content = nContent">mdi-eye-off</v-icon>
        <v-icon class="ml-auto clickable plus-btn" @click="minimize = true">mdi-minus</v-icon>
      </div>
      <div v-else :style="{
         top: (nTop+10) + 'px',
          left: nLeft + 'px',
          width: nWidth + 'px',
          height: nHeight+ 'px', position: 'absolute', color:'yellow'}">
        <v-icon class="ml-auto clickable mdi-comment-text-outline" @click="minimize = false">mdi-comment-text-outline</v-icon>
      </div>
    </div>
</template>

<script>
import { mapActions, mapState} from 'vuex';
export default {
  name: "Notes",
  props:['nTop', 'nLeft', 'nWidth', 'nHeight','nContent','id'],
  data() {
    return {
        content:'',
        width: 0,
        height:0,
        top: 0,
        left: 0,
        enable:false,
        minimize: false
    }
  },
  computed: {
    ...mapState("request", {
      request: "requestItem"
    }),
    ...mapState("documents",{
      page: "currentPage",
      document: "document"
    })
  },
  methods:{
    updateContent(){
      let data = {};
      data.request_id = this.request.id;
      data.page_num = this.page;
      data.doc_id = this.document.id;
      data.width = this.width;
      data.height = this.height;
      data.left = this.left;
      data.content = this.content;
      data.id = this.id;
      if( data.id === 0 )
      this.$emit('addContent', data);
      else
      this.$emit('updateContent', data);
    },
    deleteNote(id){
      this.removeNote(id);
    },
    ...mapActions('documents',['removeNote'])
  }
}
</script>

<style lang="scss" >
  .edit-note {
    position:absolute;
    z-index: 1;
    background: rgb(247 231 11 / 51%);
    font-size: 14px;
  }
  .edit-note textarea{
    padding:5px;
  }
  .close-btn, .minus-btn{
    color: #FFFFFF;
    background: rgba(255, 0, 0, 0.82);
    border-radius:100%;
    top:-9px;
    right: -9px;
    position: absolute;
    z-index: 1;
  }
  .minus-btn{
    top:-9px !important;
    right: 19px;
    background-color: gray !important;
    color:#FFF;
    z-index: 2 !important;
    border-radius:100%;
  }
  .plus-btn{
    border-radius:100%;
    color:white !important;
    position: absolute;
    z-index: 1;
    right:46px;
    background-color:gray;
    top: -9px !important;
  }
  .mdi-comment-text-outline{
    color: gray !important;
    background: yellow;
  }
</style>