<template><div id="c" style="height:1000px">
  <canvas :id="pageNumber" v-bind="canvasAttrs">{{pageNumber}}</canvas>
          </div>
</template>
<script>
//import pdfFindController from 'pdfjs-dist/lib/web'
import {mapState} from "vuex";

export default {
    created() {
        // PDFPageProxy#getViewport
        // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
        this.viewport = this.page.getViewport({scale:parseInt(this.scale)});
    },
    props: ['page', 'scale','imgQuality'],
    mounted() {
        this.drawPage();
    },

    render(h) {
        const {canvasAttrs: attrs} = this;
        return h('canvas', {attrs});
    },
    computed: {
        ...mapState("documents", {
          enhancement: "enhancement"
        }),
        canvasAttrs() {
            let {width, height} = this.viewport;
            [width, height] = [width, height].map(dim => Math.ceil(dim));

            const style = this.canvasStyle;

            return {
                width,
                height,
                style,
                class: 'pdf-page',
            };
        },

        canvasStyle() {
            const {width: actualSizeWidth} = this.actualSizeViewport;
            const pixelRatio = window.devicePixelRatio || 1;
            var [pixelWidth] = [actualSizeWidth]
                .map(dim => Math.ceil(dim / pixelRatio));
                pixelWidth = 800;
            return `width: ${pixelWidth}px;`
            },

        actualSizeViewport() {
            return this.viewport.clone({scale: this.scale});
        },
        pageNumber() {
            return this.page.pageNumber;
        },
        //...
    },
    watch: {
      enhancement:{
        handler(){
          this.renderTask = false;
          this.drawPage();
        },deep:true
      }
    },
    methods: {
        drawPage() {
            if (this.renderTask) return;

            let {viewport} = this;
            //viewport.width=800;
            const canvasContext = this.$el.getElementsByTagName('canvas')[0].getContext('2d');
            canvasContext.filter = 'brightness('+this.enhancement.brightness+'%) contrast('+this.enhancement.contrast + '%)';
            const renderContext = {canvasContext, viewport};
            // PDFPageProxy#render
            // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
            this.renderTask = this.page.render(renderContext).promise;

            this.renderTask.
                then(() => {this.$emit('rendered', this.page)
                //pdfFindController.executeCommand('find', { query: 'type', });
                  // this.PDFViewerApplication.findController.executeCommand('find', {
                  //   query: 'Type',
                  //   caseSensitive: false,
                  //   highlightAll: true,
                  //   findPrevious: true
                  // })
                })

        },
    }
}
</script>
  