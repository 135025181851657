<template>
  <v-container
      v-if="extension"
      fluid
      fill-height
      @mousedown="startDrawingBox"
      @mousemove="changeBox"
      @mouseup="stopDrawingBox"
  >
    <div class="pdf-preview-wrapper">
      <div class="action-bar flex-row-space-between">
        <div class="main-controller flex-row-vertical-center">
          <el-popover
              v-model="showSearchBar"
              placement="bottom"
              width="376"
              trigger="manual"
              popper-class="pdf-search-popover"
          >
            <i slot="reference" :class="['icon-search', 'el-icon-search' , {'active': showSearchBar}]" @click="toggleShowSearchBar"></i>
            <div class="search-bar flex-row-space-between">
              <el-input
                  v-model="keyword"
                  ref="searchInput"
                  @keyup.native.enter="handleMatchChange(false, canFindNext)"
              ></el-input>
              <div class="icon-wrapper flex-row-space-between">
                <i :class="['el-icon-back', {'active': canFindPre}]" @click="handleMatchChange(true, canFindPre)"></i>
                <i :class="['el-icon-right', {'active': canFindNext}]" @click="handleMatchChange(false, canFindNext)"></i>
              </div>
            </div>
          </el-popover>
          <i :class="['el-icon-arrow-left', {'active': canPageDown}]" @click="handlePageChange(-1, canPageDown)"></i>
          <div class="page-num-wrapper">
            <input
                ref="pageInput"
                class="page-input"
                :value="currentPage"
                @keyup.enter="handlePageChangeByInput"/>
            <span>/ {{ pages }}</span>
          </div>
          <i :class="['el-icon-arrow-right', {'active': canPageUp}]" @click="handlePageChange(1, canPageUp)"></i>
        </div>

        <div class="scale-controller flex-row-vertical-center">
          <i :class="['el-icon-minus', {'active': canNarrow}]" @click="handleScaleChange(-25, canNarrow)"></i>
          <span class="scale-percent">{{ currentScale }}%</span>
          <i :class="['el-icon-plus', {'active': canEnlarge}]" @click="handleScaleChange(25, canEnlarge)"></i>
        </div>
      </div>

      <div class="pdf-container">
        <pdf-viewer
            ref="pdfViewer"
            :src="preview"
            @on-loaded="updateTotalPages"
            @on-search="handleSearchResult"
            @on-page-change="handlePageChangeFromPDFViewer($event, true)"
        ></pdf-viewer>
      </div>
    </div>
    <div class="pdfFixed">
      <div class="fixed" ref="myPdfComponent">
        <clause-form
            v-model="openClauseForm"
            @input="cancelDraw"
            :drawingBox="drawingBox"
            :docBoxes="docBoxes"
            :editedIndex="editedIndex"
            @close="closeClauseForm"
        />
      </div>
    </div>
    <v-progress-circular
        v-if="loading"
        :size="70"
        indeterminate
        color="red"
    ></v-progress-circular>
  </v-container>
</template>

<script>
//import pdf from "vue-pdf";
import { mapGetters, mapState, mapActions } from "vuex";
//import Drawer from "../Drawer";
import { pick } from "lodash";
import ClauseForm from "../ClauseForm";
//import PDFPage from "./PDFPage";

const getCoursorLeft = e => {
  return e.offsetX;
};

const getCoursorTop = e => {
  return e.offsetY;
};

export default {
  components: {
    //pdf,
    //Drawer,
    ClauseForm,
    //PDFPage
  },

  data() {
    return {
      drawingBox: {
        active: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        color: "#23707696",
        clausesUsed: [],
        rationale: ""
      },
      docBoxes: [],
      editedIndex: -1,
      openClauseForm: false,
      minScale: 50,
      maxScale: 200,
      currentScale: 100,
      pages: 0,
      showSearchBar: false,
      keyword: '',
      currentMatch: 0,
      totalMatch: 0
    };
  },

  computed: {

    canEnlarge() {
      return this.currentScale < this.maxScale
    },
    canNarrow() {
      return this.currentScale > this.minScale
    },
    canPageUp() {
      return this.currentPage < this.pages
    },
    canPageDown() {
      return this.currentPage > 1
    },
    canFindPre() {
      return this.currentMatch > 1
    },
    canFindNext() {
      return this.currentMatch < this.totalMatch
    },
    ...mapGetters("documents", {
      //getPreview: "getPreview",
      boxes: "getBoxesByPage"
    }),
    ...mapState("documents", {
      isDrawing: "isDrawing",
      markupType: "markupType",
      numPages: "numPages",
      extension: "extension",
      document: "document",
      preview: "preview",
      pagePdf: "pagePdf",
      loading:"loading",
      scale:"scale",
      currentPage:"currentPage"
    })
  },

  watch: {
    currentPage(val){
      this.handleMatchChange(val, this.canFindNext)
    },
    keyword(newVal) {
      this.$refs.pdfViewer.cancelSearch()
      if (newVal) this.$refs.pdfViewer.search(newVal)
    },
    showSearchBar(newVal) {
      if (newVal) this.$nextTick(() => this.$refs.searchInput.focus())
      else this.$refs.pdfViewer.cancelSearch()
    }
  },
  created(){
    this.$store.dispatch("configuration/retriveConfigurations");
    this.$store.dispatch("consultation/retrieveConsultations");
    this.$store.dispatch("address/retrieveAddresses");
  },
  mounted() {
    document.onkeydown = (e) => {
      if (e.ctrlKey && e.key === 'f') {
        this.showSearchBar = true
        e.preventDefault()
      } else if (e.key === 'Escape') {
        this.showSearchBar = false
      }
    }
    this.$root.$on("PDFEditModePage", () => {
      this.editedIndex = -1;
      this.setWithHeldPage();
    });
    this.$root.$on("PDFEditModeDocument", () => {
      this.editedIndex = -1;
      this.setWithHeldDocument();
    });
    this.$root.$on("PDFEditModeRectangle", () => {
      this.editedIndex = -1;
      this.setWithHeldRectangle();
    });
  },

  methods: {
    toggleShowSearchBar() {
      this.showSearchBar = !this.showSearchBar
    },
    updateTotalPages(pages) {
      this.pages = pages
    },
    handleScaleChange(value, changeable) {
      if (changeable) {
        this.currentScale += value
        this.$refs.pdfViewer.zoom(this.currentScale / 100)
      }
    },
    handlePageChange(value, changeable) {
      if (changeable){
        this.setCurrentPage(this.currentPage + value)
        this.$refs.pdfViewer.jumpToPage(this.currentPage)
      }
    },
    handlePageChangeFromPDFViewer(page) {
      this.setCurrentPage(page);
    },
    handlePageChangeByInput() {
      const el = this.$refs.pageInput;
      if (isNaN(el.value.trim())) return this.$message.error(this.$t('error.wrongPageNumber'))
      const page = parseFloat(el.value.trim())
      if (page < 1 || page > this.pages || page % 1 !== 0) return this.$message.error(this.$t('error.wrongPageNumber'))
      el.blur()
      this.setCurrentPage(page);
      this.$refs.pdfViewer.jumpToPage(this.currentPage)
    },
    handleMatchChange(prev, changeable) {
      if (changeable) {
        this.$refs.pdfViewer.searchAgain(prev)
        this.currentMatch += prev ? -1 : 1
      }
    },
    handleSearchResult({ current, total }) {
      this.currentMatch = current
      this.totalMatch = total
    },
    cancelDraw() {
      this.retrieveDocumentInfo(this.document.id);
      this.drawingBox = {
        active: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        color: "#23707696",
        clausesUsed: [],
        rationale: ""
      }
    },

    startDrawingBox(e) {
      if(this.markupType === "Rectangle"){
        if (this.isDrawing) {
          this.drawingBox = {
            width: 0,
            height: 0,
            top: getCoursorTop(e),
            left: getCoursorLeft(e),
            active: true,
            color: "#23707696",
            clausesUsed: [],
            rationale: ""
          };
        }
      }
    },

    changeBox(e) {
      if (this.drawingBox.active) {
        this.drawingBox = {
          ...this.drawingBox,
          width: getCoursorLeft(e) - this.drawingBox.left,
          height: getCoursorTop(e) - this.drawingBox.top
        };
      }
    },

    stopDrawingBox() {
      if (this.isDrawing) {
        if (this.drawingBox.width > 5) {
          this.boxes.push({
            ...pick(this.drawingBox, [
              "width",
              "height",
              "top",
              "left",
              "color"
            ])
          });
          this.openClauseForm = true;
        }
      }
      this.drawingBox.active = false;
      this.setIsDrawing(false);
    },
    closeClauseForm() {
      this.openClauseForm = false;
      this.setIsDrawing(false);
      this.boxes.pop();
      this.drawingBox = {
        active: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        color: "#23707696",
        clausesUsed: [],
        rationale: ""
      };
    },

    setWithHeldRectangle() {
      this.setIsDrawing(true);
      this.setMarkupType('Rectangle');
      this.drawingBox = {
        active: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        color: "#23707696",
        clausesUsed: [],
        rationale: ""
      };
    },

    setWithHeldPage() {
      this.setMarkupType('Page');
      const canvasHeitht = this.$refs.myPdfComponent.clientHeight;
      this.drawingBox = {
        active: false,
        height: canvasHeitht,
        width: 800,
        top: 0,
        left: 0,
        page: this.page,
        color: "#23707696",
        clausesUsed: [],
        rationale: ""
      };
      this.boxes.push({
        ...pick(this.drawingBox, ["width", "height", "top", "left", "color"])
      });
      this.openClauseForm = true;
    },
    setWithHeldDocument() {
      this.setMarkupType('Document');
      const canvasHeitht = this.$refs.myPdfComponent.clientHeight;
      for (let index = 1; index <= this.numPages; index++) {
        this.drawingBox = {
          active: false,
          height: canvasHeitht,
          width: 800,
          top: 0,
          left: 0,
          pageNum: index,
          color: "#23707696",
          clausesUsed: [],
          rationale: ""
        };
        this.docBoxes.push({
          ...pick(this.drawingBox, [
            "width",
            "height",
            "top",
            "left",
            "color",
            "pageNum"
          ])
        });
      }
      this.openClauseForm = true;
    },

    editBox(id) {
      this.drawingBox = this.boxes.find(x => x.id == id);
      this.editedIndex = this.boxes.findIndex(item => item.id == id);
      this.openClauseForm = true;
    },

    ...mapActions("documents", ["setIsDrawing", 'setMarkupType', 'retrieveDocumentInfo','setCurrentPage'])
  }
};
</script>

<style lang="scss">

.v-progress-circular{
  left: 50%;
  top: 50%;
  position: fixed;
  z-index: 9;
}
.pdfFixed {
  width: 800px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
// .fixed > span > span{
//   transform: scale(1) !important;
// }
.fixed {
  width: 800px;
  position: fixed;
  /* Do not set top / left! */
}
</style>

<style scoped lang="sass">
.flex-row-space-between
  display: flex
  justify-content: space-between
  align-items: center
.flex-row-vertical-center
  display: flex
  align-items: center
.pdf-preview-wrapper
  width: calc(100vw - 256px)
  height: calc(100vh - 64px)
  position: fixed
  top: 64px
  right: 0
  color: #606470
  .action-bar
    height: 60px
    background-color: #1B2539
    padding: 0 24px
    i
      font-size: 20px
    .active
      color: white
      cursor: pointer
    .main-controller
      width: 30%
      .icon-search
        margin-right: 24px
        cursor: pointer
        &:focus
          outline: none
        &.active
          color: #6595FF
      .page-num-wrapper
        margin: 0 16px
        .page-input
          width: 50px
          height: 28px
          padding: 7px 8px
          margin-right: 8px
          text-align: right
          outline: none
          color: white
          background-color: #1B2539
          box-sizing: border-box
          border: 1px solid rgba(96, 100, 112, 0.50)
          border-radius: 2px
    .scale-controller
      .scale-percent
        width: 40px
        text-align: center
        margin: 0 16px
        color: white
    .cancel-wrapper
      width: 30%
      text-align: right
      i
        color: white
        cursor: pointer
  .pdf-container
    width: 100%
    height: calc(100% - 60px)
    position: relative
    box-sizing: border-box
    background: rgba(53, 50, 48, 0.80)
</style>

<style lang="sass">
.pdf-search-popover.el-popover
  padding: 8px 0 8px 8px
  background-color: #293247
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.50)
  border: none
  .search-bar
    .el-input
      width: 280px
      .el-input__inner
        height: 32px
        background-color: #1B2539
        border: 1px solid rgba(96, 100, 112, 0.50)
        color: white
        &:focus
          border: 1px solid #6595FF
  .icon-wrapper
    width: 56px
    margin: 0 16px
    i
      font-size: 20px
      &.active
        color: white
        cursor: pointer
  .popper__arrow
    border-bottom-color: #293247 !important
    &::after
      border-bottom-color: #293247 !important
</style>
