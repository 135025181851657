var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-wrapper"},[_c('div',{staticClass:"box",style:({
			top: _vm.bTop + 'px',
			left: _vm.bLeft + 'px',
			width: _vm.bWidth + 'px',
			height: _vm.bHeight + 'px',
            backgroundColor: _vm.color,
            border: _vm.border,
			})},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [(_vm.mode == 'edit')?_c('v-btn',_vm._g({staticClass:"delete",attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.localDelete(_vm.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete-forever")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Delete")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [(_vm.mode == 'edit')?_c('v-btn',_vm._g({staticClass:"edit",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editBox(_vm.id)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Edit")])]),(_vm.showClauses)?_vm._l((_vm.clauses),function(clause,i){return _c('div',{key:(i + "-" + clause),staticClass:"d-flex"},[_c('span',{style:({color: _vm.color=='#000'? 'white' : 'black'})},[_vm._v(_vm._s(clause))])])}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }