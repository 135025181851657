<template>
  <div>
    <v-alert v-if="alert" type="success" class="alert">Document saved successfully</v-alert>
    <v-alert v-if="calert" type="success" class="alert">Document ready for consultation</v-alert>
    <v-alert v-if="cerror" type="error" class="alert">Document fail for consultation</v-alert>
    <side-bar />
    <nav-bar />
    <auto-logout v-if="isLoggedin" style="position: absolute; top:0; left:0; z-index:10; background-color:white"/>
     <Main />
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import SideBar from "../components/documents/SideBar";
import NavBar from "../components/documents/NavBar";
import Main from "../components/documents/Main";
import Footer from "../components/documents/Footer";
import AutoLogout from "../components/AutoLogout";

export default {
  components: {
    NavBar,
    SideBar,
    Main,
    Footer,
    AutoLogout
  },

  data() {
    return {
      id: this.$route.params.request_id
    };
  },

    created() {
      this.$i18n.locale= localStorage.getItem('language') ;
  },

  async mounted() {
    if (this.id) {
      await this.retriveRequestItem(this.id);
    }
  },

  computed: {
      ...mapState('documents', {
          alert: 'alert',
          cerror: 'cerror',
          calert: 'calert'
      }),

      ...mapGetters({
        isLoggedin: 'isAuthenticated'
      })
  },

  methods: {
    ...mapActions("request", ["retriveRequestItem"])
  }
};
</script>

<style lang="scss" scoped>
.alert{
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 100;
}
</style>