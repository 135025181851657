<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="0"
          class="ml-5"
          color="primary"
          dark
          v-on="on"
          @click="dialog = true"
          :disabled="numPages == 0 || !request.reqCode || mode == 'edit'"
        >
          <v-icon>mdi-file-document-edit-outline</v-icon>
        </v-btn>
      </template>
      <span>Create package for released</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>Consultation Package</v-card-title>
            <v-container style="height: 400px;" v-if="print">
                <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                    class="subtitle-1 text-center"
                    cols="12"
                    >
                    Creating package for consultation
                    </v-col>
                    <v-col cols="6">
                    <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                    </v-col>
                </v-row>
            </v-container>
        <v-card-actions>
          <v-btn class="custom-color-accent-text" text @click="dialog = false">{{$t('cancel')}}</v-btn>
           <v-btn :disabled="print" class="custom-color-accent-text" text @click="send">{{$t('download')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="downloaddialog" max-width="500px">
      <v-card>
        <v-card-title>Consultation Package</v-card-title>
            <v-container style="height: 400px;" v-if="print">
                <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                    class="subtitle-1 text-center"
                    cols="12"
                    >
                    Downloading package for consultation
                    </v-col>
                    <v-col cols="6">
                    <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                    </v-col>
                </v-row>
            </v-container>
        <v-card-actions>
          <v-btn class="custom-color-accent-text" text @click="dialog = false">{{$t('cancel')}}</v-btn>
           <v-btn :disabled="print" class="custom-color-accent-text" text @click="send">{{$t('download')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      downloaddialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      chips: [],
      manu: false,
      loadText: "Creating package for consultation",
      // style: '',
      // select: [{ text: "White rectangle with border" }, { text: "Highlights" }],
      rectangleStyle: 'transparent'
    };
  },
  computed: {
    ...mapState("documents", {
      mode: "mode",
      document: "document",
      numPages: "numPages",
      print: "print"
    }),
    ...mapState('request', {
        request: "requestItem"
    })
  },
  methods: {

      async send() {
        this.dialog = false;
        this.downloaddialog = true;
          
        var response = await this.printConsultant(this.request.id)
        
        if(response.status == 200)
        this.downloaddialog = false;
      },
      ...mapActions('documents', ['savePDF','printConsultant'])
  },
  watch:{
      dialog(value){
          if(value && this.request.id !== null)
            this.savePDF(this.request.id);
      }
  }
};
</script>