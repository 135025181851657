var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notes-wrapper"},[(!_vm.minimize)?_c('div',{staticClass:"edit-note",style:({
       top: _vm.nTop + 'px',
        left: _vm.nLeft + 'px',
        width: _vm.nWidth + 'px',
        height: _vm.nHeight + 'px'})},[(_vm.enable)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],style:({
       top: (_vm.nTop+10) + 'px',
        left: _vm.nLeft + 'px',
        width: _vm.nWidth + 'px',
        height: _vm.nHeight+ 'px'}),attrs:{"readonly":!_vm.enable,"border":"1","id":'note-text-'+_vm.id,"height":"100%","placeholder":_vm.$t('addnote')},domProps:{"value":(_vm.content)},on:{"change":function($event){return _vm.updateContent()},"input":function($event){if($event.target.composing){ return; }_vm.content=$event.target.value}}}):_c('span',[_vm._v(_vm._s(_vm.content))]),_c('v-icon',{staticClass:"ml-auto clickable close-btn",on:{"click":function($event){return _vm.deleteNote(_vm.id)}}},[_vm._v("mdi-close")]),(_vm.enable)?_c('v-icon',{staticClass:"ml-auto clickable minus-btn",on:{"click":function($event){_vm.enable = false, _vm.content = ''}}},[_vm._v("mdi-eye")]):_c('v-icon',{staticClass:"ml-auto clickable minus-btn",on:{"click":function($event){_vm.enable = true, _vm.content = _vm.nContent}}},[_vm._v("mdi-eye-off")]),_c('v-icon',{staticClass:"ml-auto clickable plus-btn",on:{"click":function($event){_vm.minimize = true}}},[_vm._v("mdi-minus")])],1):_c('div',{style:({
       top: (_vm.nTop+10) + 'px',
        left: _vm.nLeft + 'px',
        width: _vm.nWidth + 'px',
        height: _vm.nHeight+ 'px', position: 'absolute', color:'yellow'})},[_c('v-icon',{staticClass:"ml-auto clickable mdi-comment-text-outline",on:{"click":function($event){_vm.minimize = false}}},[_vm._v("mdi-comment-text-outline")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }