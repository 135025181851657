<template>
  <div class="box-wrapper">
    <div
      class="box"
      :style="{
			top: bTop + 'px',
			left: bLeft + 'px',
			width: bWidth + 'px',
			height: bHeight + 'px',
            backgroundColor: color,
            border: border,
			}"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="delete" v-if="mode == 'edit'" icon color="red" @click="localDelete(id)" v-on="on">
            <v-icon>mdi-delete-forever</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="edit" v-if="mode == 'edit'" icon color="primary" @click="editBox(id)" v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
      <template v-if="showClauses">
      <div class="d-flex" v-for="(clause, i) in clauses"  :key="`${i}-${clause}`">
           <span :style="{color: color=='#000'? 'white' : 'black'}">{{clause}}</span>
      </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: "Box",
  props: ["b-top", "b-left", "b-width", "b-height", "id", "clauses"],
  methods: {
    localDelete(id) {
      if(confirm('Are you sure you want to delete this box?')) {
         this.deleteBox(id);
         this.$emit('deleteBox', id);
      }
    },

    editBox(id) {
      this.$emit("editBox", id);
    },

    ...mapActions("documents", ["deleteBox"])
  },

  computed: {
    ...mapState("documents", {
      mode: 'mode',
      color: 'boxColor',
      border: 'boxBorder',
      showClauses: 'showClauses'
    })
  }
};
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  text-align: right;
  z-index: 3;
}
.edit{
  position: absolute;
  right: 36px;
  top: -36px;
  background: #237076a6;
  border-radius: 0;
}
.delete{
  position: absolute;
  right: 0;
  top: -36px;
  background: #237076a6;
  border-radius: 0;
}
</style>