<template>
  <v-content>
    <PDFEditMode v-if="preview && mode=='edit'" :key="preview"/>
    <PDFViewMode v-if="preview && mode=='view'" :key="preview"/>
  </v-content>
</template>

<script>
import { mapState } from "vuex";
import PDFEditMode from "./PDFEditMode";
import PDFViewMode from "./PDFViewMode";

export default {
  components: {
    PDFEditMode,
    PDFViewMode
  },
  created() {
    this.$store.dispatch("consultation/retrieveConsultations");
  },
  data() {
    return {
    }
  },

  computed: {
    ...mapState("documents", {
      preview: "preview",
      page: "activePage",
      mode: "mode",
      numPages: "numPages",
    })
  }
};
</script>