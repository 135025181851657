var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0 my-3 elevation-0 white"},[_c('div',{staticClass:"my-border"},[_c('v-card-title',{staticClass:"py-0 my-0 customlightgray"},[_c('v-row',{staticClass:"ma-0 pa-0 py-2 justify-center text-left"},[_c('v-col',{staticClass:"pa-0 ma-auto",attrs:{"cols":"6","sm":"4","md":"4","lg":"4","order":"1","order-md":"1"}},[_c('div',{staticClass:"font-weight-medium text-left text--secondary"},[_c('div',{staticClass:"ma-auto pa-auto"},[_c('span',{staticClass:"ma-0 pa-1"},[_vm._v(_vm._s(_vm.tableTitle))]),_c('span',{staticClass:"custom-color-accent pa-1",staticStyle:{"border-radius":"22px","font-size":"0.6em"}},[_vm._v(_vm._s(_vm.items.length)+" ")])])])]),_c('v-col',{staticClass:"ma-0 pa-0 text-center",attrs:{"order":"3","order-md":"2","cols":"12","sm":"12","md":"4","lg":"4"}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"dense":"","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right pa-0 my-2 ma-md-auto",attrs:{"cols":"6","sm":"8","md":"4","lg":"4","order":"2","order-md":"3"}},[(_vm.request!=null)?_c('v-btn',{staticClass:"ma-auto pa-auto custom-color-accent",attrs:{"depressed":"","to":{ name: 'editor', params: { request_id: _vm.request.id } },"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("openeditor")))]):_vm._e()],1)],1)],1),_c('v-divider'),(_vm.items)?_c('v-data-table',{staticClass:"elevation-0 pa-0 ma-0 text--secondary customoffwhite",attrs:{"mobile-breakpoint":1081,"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"sort-by":"id","search":_vm.search,"loading-text":_vm.$t('Loading'),"footer-props":{
      'items-per-page-text':_vm.$t('rowsperpage')
    }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noDataAvailable')))]},proxy:true},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.sent_out_date",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.sent_out_date)))])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.sendmail(item)}}},[_vm._v("mdi-mail")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteConsult(item.id)}}},[_vm._v("mdi-delete")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.getConsultDoc(item.doc_file_name)}}},[_vm._v("mdi-cloud-download")])]}}],null,true)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }