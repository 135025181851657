<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-list dense class="mb-10">
      <v-list-item>
        <v-list-item-action>
          <v-icon>mdi-text-box-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{$t('documents')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item-group class="documents-list" v-model="selectedDoc">
        <v-list-item
          class="doc-item"
          v-for="(item, i) in regDoc"
          :key="i"
          @click="openDocument(item)"
        >
          <span class="d-inline-block text-truncate" style="max-width: 200px;">{{ item.name }}</span>
          </v-list-item>
        <v-chip
          v-if="errored"
          class="ma-2"
          close
          color="red"
          text-color="white"
          @click:close="errored = false"
        >An error occurred when trying to open the document.</v-chip>
      </v-list-item-group>
      
    </v-list>
    <v-footer absolute class="align-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            {{ $t('action') }}
          </v-btn>
        </template>
        <v-list>
          <send-consultation></send-consultation>
          <send-pcoconsultation></send-pcoconsultation>
          <consultation :request="request" :consultDoc="consultDoc"></consultation>
          <pcoconsultation :request="request"></pcoconsultation>
        </v-list>
      </v-menu>
    </v-footer>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
//import pdf from "vue-pdf";
import pdfjs from "pdfjs-dist";
import axios from "axios";
import SendConsultation from "../consultation/SendConsultation";
import SendPcoconsultation from "../consultation/SendPcoConsultation";
import Consultation from "@/components/sidebar/consultation";
import pcoconsultation from "@/components/sidebar/pcoconsultation";


export default {
  components: {
    SendConsultation,
    pcoconsultation,
    SendPcoconsultation,
    Consultation
  },
  data() {
    return {
      drawer: true,
      request_id: this.$route.params.request_id,
      dragging: -1,
      src: undefined,
      numPages: 0,
      error: "",
      errored: false,
      selectedDoc: [],
      selectAll:"",
      openPCO: false
    };
  },

  async created() {
    await this.retrieveDocuments();
  },

  mounted(){
    this.$root.$on("toggleSideDrawer", () => {
      this.drawer = !this.drawer;
    });
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.$store.dispatch("configuration/retriveConfigurations");
  },

  computed: {
    ...mapGetters('documents',{
      getRegDocs: 'getRegDocs',
      getConsultDoc: 'getConsultDoc',
    }),
    ...mapGetters('consultation',{
      getConsultDoc: 'getConsultDoc',
    }),
    regDoc() {
      return this.getRegDocs(this.$route.params.request_id)
    },
    consultDoc() {
      return this.request.id ? this.getConsultDoc(this.request.id) : []
    },
    ...mapState("documents", {
      file: "file",
      mode: "mode",
      document: "document",
      preview: "preview",
      markedDocs:"markedDocs"
    }),

    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    }),
    ...mapState('request', {
      request: 'requestItem'
    })
  },
  methods: {
    send(){},
    async openDocument(item) {
      try {
        const preview = `${axios.defaults.baseURL}/document/file/${item.id}`;

        pdfjs.getDocument(preview).promise.then((pdf)=>{
          this.setMode('edit');
          this.setPDFPage(pdf);
          this.setNumPages(pdf.numPages);
          this.setPreview(preview);
          this.setPageStatus({request_id: this.request_id, id: item.id, currentPage: 1 })
        });
        
        const extension = item.path.split(".").pop();
        this.setExtension(extension);
        this.setCurrentPage(1);
        this.setDocumentId(item.id);
        this.retrieveDocumentInfo(item.id);
      } catch (error) {
        this.error = error;
        this.errored;
      }
    },

    ...mapActions("documents", [
        "retrieveDocuments",
      "setPreview",
      "setNumPages",
      "setDocumentId",
      "setCurrentPage",
      "retrieveDocumentInfo",
      "setExtension",
      "setPDFPage",
      "setDocStatus",
      "setPageStatus",
        "setMode"
    ])
  },

};
</script>

<style lang="scss" scoped>
.documents-list {
  list-style-type: none;
  padding: 10px;
}

.doc-item {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 14px 8px;
  margin-bottom: 3px;
  background-color: #fff;
  box-shadow: 1px 2px 2px #ccc;
  font-size: 16px;
}

.page {
  min-width: 50%;
  display: inline-block;

  &:hover {
    border: 1px solid blue;
  }
}

.v-list-item-group .v-list-item--active {
    color: white;
    background-color: #2f82d6;
}
.v-list-item__action{
  margin-left: 0 !important;
  margin:0;
  cursor: pointer;

}
.pointer{
  cursor:pointer;
}
</style>