<template>
  <v-card class="ma-0 pa-0 my-3 elevation-0 white">
    <div class="my-border">
      <v-card-title class="py-0 my-0 customlightgray">
        <v-row class="ma-0 pa-0 py-2 justify-center text-left">
          <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
            <div class="font-weight-medium text-left text--secondary">
              <div class="ma-auto pa-auto">
                <span class="ma-0 pa-1">{{tableTitle}}</span>
                <span style=" border-radius: 22px; font-size:0.6em;"
                      class="custom-color-accent pa-1">{{items.length}}
                    </span>
              </div>
            </div>
          </v-col>
          <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
            cols="12" 
            sm="12"
            md="4"
            lg="4">  
                <v-text-field
                  v-model="search"
                  class="ma-0 pa-0"
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  dense 
                  outlined
                  clearable
                  hide-details
                >
            </v-text-field> 
            </v-col>
          <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3">
            <v-btn class="ma-auto pa-auto custom-color-accent" v-if="request!=null" depressed :to="{ name: 'editor', params: { request_id: request.id } }" target="_blank">{{$t("openeditor")}}</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
      v-if="items"
    :items="items"
    :headers="headers"
    :options="options"
    sort-by="id"
    class="elevation-0 pa-0 ma-0 text--secondary customoffwhite"
    :search="search"
    :loading-text="$t('Loading')"
    :footer-props="{
        'items-per-page-text':$t('rowsperpage')
      }"
  >
    <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
    <template v-slot:[`item.created_at`]="{item}">
      <div>{{formatDate(item.created_at)}}</div>
    </template>
    <template v-slot:[`item.sent_out_date`]="{item}">
      <div>{{formatDate(item.sent_out_date)}}</div>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon small class="mr-2" @click="sendmail(item)">mdi-mail</v-icon>
      <v-icon small class="mr-2" @click="deleteConsult(item.id)">mdi-delete</v-icon>
      <v-icon small class="mr-2" @click="getConsultDoc(item.doc_file_name)">mdi-cloud-download</v-icon>
    </template>
  </v-data-table>
    </div>
  </v-card>
</template>
<script>

import {mapActions, mapState} from "vuex";
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
export default {
  props: ["request"],
  data() {
    return {
      items: this.request.pcoconsultant,
      search: "",
      dialog: false,
      selected: [],
      options:{
        sortDesc: true
      },
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: true,
          value: "id"
        },
        { text: this.$t('requestid') , value: "req_code" },
        { text: this.$t('contactname'), value: "contact_name" },
        { text: this.$t('filename'), value: "doc_file_name" },
        { text: this.$t('datecreated'), value: "created_at" },
        { text: this.$t('datesent'), value: "sent_out_date" },
        { text: "", value: "action", sortable: false }
      ],
      address: [],
      editedIndex: -1
    };
  },
  created() {
    this.items = this.request.pcoconsultations;
  },
  computed: {
    ...mapState('pcoconsultation', {
      pcoconsultations: "pcoconsultations",
      loading: "loading"
    }),
    tableTitle() {
      return this.$t("PCOConsultations");
    },
  },
  methods: {
    getConsultDoc( docname ){
      return new Promise((resolve, reject) => {
        axcessApi.getRequest('/pcoconsultdoc/' + this.request.id + '/docid/' + docname,{responseType: 'arraybuffer',headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }})
            .then(res => {
              let blob = new Blob([res.data], {type:'application/*'})
              let link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = docname
              link._target = 'blank'
              link.click();
              resolve(res)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    formatDate(value) {
      return value != null ? moment(value).format('YYYY-MM-DD') : "";
    },
    deleteConsult(id) {
      if (confirm(this.$t("Are you sure you want to delete this item?"))) {
        this.deleteReqConsult(id);
      }
    },
    async sendmail(item){
      await this.updateSentdate(item.id);
      let url = this.generateUuid(item);
      url.then(result => {
        const response = axcessApi.getRequest("/address/"+item.agency_id,{headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }});
        response.then(output => {
          let link = "mailto:"+ output.data.Email
              + "?subject=" + encodeURIComponent("Request "+ item.req_code + " Consultation" )
              + "&body=" + encodeURIComponent('Good Day, '+ item.contact_name + ' You can download consultation file by clicking on the following link: ' + result.data)
          ;
          // + "?cc=myCCaddress@example.com"
          window.location.href = link;
        })
      })
    },
    ...mapActions("pcoconsultation", ["deleteReqConsult","updateSentdate",'filterConsultations']),
    ...mapActions('address', ['generateUuid']),
  }
};
</script>

<style scoped>
.status-chip {
  width: 130px;
}
</style>