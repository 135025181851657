<template>
  <div v-if="1">
    <v-list-item>
      <v-list-item-title class="pointer" @click="dialogConsult()">{{ $t('Generate PCO Schedule')}}</v-list-item-title>
    </v-list-item>
    <v-dialog v-model="dialogConsultation" max-width="600px">
      <v-card>
        <v-card-title>PCO Consultation Package</v-card-title>
          <v-card-text>
            <v-treeview open-all :items="consultPerson">
            </v-treeview>
          </v-card-text>
        <v-card-actions>
          <v-btn class="custom-color-accent-text" text @click="dialogConsultation = false">{{$t('cancel')}}</v-btn>
          <v-btn :disabled="!consultPerson.length > 0" class="custom-color-accent-text" text @click="consultPackageDownload()">{{$t('save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import moment from 'moment';
import axcessApi from "@/plugins/axcessApi";
//import draggable from 'vuedraggable';
import Sortable from 'sortablejs';

export default {
  components:{
   // draggable
  },
  data() {
    return {
      open: true,
      expanded:[],
      tableHeaders: [
          {
            text: "Move",
            value: "move",
            sortable: false
          },
          {
            text: "Document Name",
            value: "name"
          },
          {
            text: "Pages",
            value: "pageCount"
          },
          {
            text: "Date",
            value: "date"
          }
      ],
      dialog: false,
      dialogConsultation: false,
      notifications: false,
      sound: true,
      widgets: false,
      chips: [],
      // style: '',
      // select: [{ text: "White rectangle with border" }, { text: "Highlights" }],
      rectangleStyle: 'transparent',
      errored: false,
      selectedDocs:[],
      docList:[],
      singleSelect: false,
      selected: [],
      choose_pages:[],
      consultPerson:[],
      documents:[]

    };
  },
  mounted() {
      //this.retrieveAddresses();
  },

  watch: {
    selected(value){
      let docs = value.map(item=>item.name);
      this.selectedDocs = docs;
      this.setSelectedDocs(docs);
    },
    getDocuments(items){
      this.docList = items;
    }
  },
  computed: {
    ...mapState("documents", {
      mode: "mode",
      document: "document",
      numPages: "numPages",
      processState: "processState",
      conLoading: "conLoading",
      markedPages:"markedPages",
      docs: "docs"
    }),
    ...mapState("address", {
        address: "addresses"
    }),
    ...mapState('request', {
        request: "requestItem"
    }),
    ...mapGetters("documents", {
      getDocuments: "getRegDocs"
    }),
    ...mapState('createConsultation',{
      consultants: 'consultants'
    })
  },
  methods: {
      async dialogCreateRelease(){
        let $this = this;
        await setTimeout(()=>{
          const lists = document.querySelectorAll('.v-data-table tbody');
          lists.forEach(list => {
            Sortable.create(list, {
              animation: 150,
              easing: 'cubic-bezier(1, 0, 0, 1)',
              group: {
                name: 'draggableItems',
              },
              sort: true,
              onEnd({ a,b,c,newIndex, oldIndex }) {
                console.log('newIndex: ', a);
                console.log('oldIndex: ', b);
                console.log('oldIndex: ', c);

                $this.arrangeDocument($this.docList,oldIndex,newIndex);
                $this.setSelectedDocs($this.selectedDocs);
              },
            });
          });
        },1000);
        $this.dialog = true;
      },
      async consultPackageDownload(){
        console.log('test');
        let totalPage = 0;
        let docTimestamp = this.request.reqCode + "-Consultation-"+moment().unix()+".pdf";
        this.updateCondocname(docTimestamp);
        let allDocIds = [];
        this.consultPerson.forEach( j => {
          totalPage += j.children.reduce( (sum,el) => sum + el.children.length , 0);
        })
        this.generateConsultDoc( this.consultPerson );
        await this.consultPerson.forEach( j => {
            let consultantName = this.address.filter(i=> i.id === j.id);
            allDocIds.push( j.children.map( id => id.id )[0] )
            let consult = {
              req_code: this.request.reqCode,
              organization_id: this.request.org_id,
              request_id: this.request.id,
              doc_file_name: this.request.reqCode + "-Consultation-"+moment().unix()+".pdf",
              //reqCode + "-Consultation-"+a.id+".pdf";
              consult_page_count: totalPage,
              all_pages: this.markedPages,
              sent_out_date: '',
              due_back_date: moment().add(20, 'days').format("YYYY-MM-DD"),
              agency_id: consultantName[0]?.id,
              agency_acronym: consultantName[0]?.Acronym,
              contact_name: consultantName[0]?.FirstName + ' ' + consultantName[0]?.LastName,
              doc_id: JSON.stringify( j.children.map( id => id.id ) )
            }
            this.addRequestPcoConsultation(consult);
          //}
        })
      },
      async dialogConsult(){
        this.consultPerson = [];
        await axcessApi.getRequest('/pagestatus/'+ this.request.id ).then( response =>{
          if( response.data.length ){
            response.data.forEach( i => {
              let consultant = JSON.parse( i.consultants );
              if( consultant ){
                consultant.forEach( j => {
                  if( this.consultPerson && this.consultPerson.length ){
                    if(this.consultPerson.filter( k=> k.id === j ).length){ //Existing number of consultant
                      this.consultPerson.forEach( (consult,ci) => {
                        if( consult.id === j ){
                          if( this.consultPerson[ci].children && this.consultPerson[ci].children.length ){
                            if( this.consultPerson[ci].children.filter( p=> p.id === i.doc_id ).length ){
                              this.consultPerson[ci].children.forEach((doc,di)=>{
                                if( doc.id === i.doc_id && this.consultPerson[ci].id === j ){
                                  this.consultPerson[ci].children[di].children.push({id: i.page_num, name: "Page " + i.page_num});
                                }
                              })
                            }else{
                              this.consultPerson[ci].children.push( {id: i.doc_id,name: "Doc " + i.doc_id, children:[{id: i.page_num, name: "Page " + i.page_num} ]});
                            }
                          }else{
                            this.consultPerson[ci].children.push({id: i.doc_id,name: "Doc " + i.doc_id, children:[{id: i.page_num, name: "Page " + i.page_num}] });
                          }
                        }
                      })
                    }else{
                      let consultantName = this.address.filter(i=> i.id === j);
                      this.consultPerson.push( {
                        id:j,
                        name: consultantName[0]?.FirstName +" "+ consultantName[0]?.LastName,
                        children:[{id: i.doc_id,name: "Doc "+ i.doc_id, children:[{id: i.page_num, name: "Page " + i.page_num}] }]
                      });
                    }
                  }else{
                    if(this.address){
                      let consultantName = this.address.filter(i=> i.id === j);
                      this.consultPerson = [{
                        id:j,
                        name: consultantName[0]?.FirstName +" "+ consultantName[0]?.LastName,
                        children:[{id: i.doc_id,name: "Doc " + i.doc_id, children:[{id: i.page_num, name: "Page " +i.page_num}] }]
                      }]
                    }
                  }
                })
              }
            } )
          }
        });
        this.dialogConsultation = true
      },
      formatDate(date){
        return moment(date).format('YYYY-MM-DD');
      },
      arrangeDocument(items, from, to) {
        if (to === -1) {
          items.splice(from, 1);
        } else {
          let removed = items[from];
          items.splice(from, 1);
          items.splice(to, 0, removed);
          this.docList = items;
          this.selectedDocs = this.docList.filter( item => this.selectedDocs.includes(item.name)).map(item=>item.name);
        }
      },
      saveSettings() {
        console.log('Changing style');
          this.setShowClause(true);
          this.setRectangleStyle(this.rectangleStyle);
          this.setBoxColor(this.rectangleStyle);
      },
      async send() {
        let docTimestamp = this.request.reqCode + "-Consultation-"+moment().unix()+".pdf";
        this.updateCondocname(docTimestamp);
        let response = await this.setupConMarkup();
        let consult = {
          req_code: this.request.reqCode,
          organization_id: this.request.org_id,
          request_id: this.request.id,
          //doc_id: this.document.id,
          doc_file_name: docTimestamp,
          consult_page_count: this.numPages,
          all_pages: this.markedPages,
          sent_out_date: '',
          due_back_date: moment().add(20, 'days').format("YYYY-MM-DD"),
        }
        if(response){

          this.chips.forEach(item => {
              consult.agency_id = item.id;
              consult.agency_acronym = item.Acronym;
              consult.contact_name = `${item.FirstName} ${item.LastName}`;
              this.addRequestPcoConsultation(consult);
              //this.sendConsultation(item);
              // let url = this.generateUuid(item);
              // console.log(url);
              this.request.RequestStatus = 'Consult';
              this.updateRequestStatus(this.request);
          });
          this.$store.dispatch("consultation/filterConsultations");
          //this.dialog = false;
        }
        
      },

      remove (item) {
        this.chips.splice(this.chips.indexOf(item), 1)
        this.chips = [...this.chips]
    },

      ...mapActions('address', ['retrieveAddresses', 'sendConsultation','generateUuid', 'addRequestPcoConsultation']),
      ...mapActions('request', ['updateRequestStatus','retriveRequests']),
      ...mapActions('documents', ['abc', 'setShowClause', 'setRectangleStyle', 'setBoxColor','setupConMarkup','updateCondocname','setSelectedDocs'])

  }
};
</script>
<style lang="scss" scoped>
.doc-item{
  cursor: move;
}
.pointer{
  cursor:pointer;
}
</style>