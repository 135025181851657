<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="600">
    <v-card>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" editable step="1" default>Select Clause ID</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" editable step="2">Rationale</v-stepper-step>
          <v-divider></v-divider>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-combobox
              v-model="drawingBox.clausesUsed"
              :items="getClause()"
              chips
              clearable
              label="Clauses"
              multiple
              prepend-icon="mdi-filter_list"
              solo
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ Array.isArray( item ) ? item.value : item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
            <v-btn color="red darken-1" text @click.stop="$emit('input'), setIsDrawing(false), e1=1">{{$t('cancel')}}</v-btn>
            <v-btn
              :disabled="!drawingBox.clausesUsed.length && markupType !== 'Rectangle'"
              color="green darken-1"
              text
              @click="e1 = 2"
            >Continue</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card-title class="subtitle-2">Rationale</v-card-title>
            <v-textarea class="mx-2" label="Rationale" solo rows="3" v-model="drawingBox.rationale"></v-textarea>
            <v-divider />
            <v-btn color="red darken-1" text @click="$emit('input'), setIsDrawing(false), e1=1">{{$t('cancel')}}</v-btn>
            <v-btn :disabled="!drawingBox.clausesUsed.length" color="green darken-1" text @click="save">{{$t('save')}}</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>
<script>
import { CLAUSES } from "../helpers/exports";
import { mapState, mapActions } from "vuex";

export default {
  props: ["drawingBox", "docBoxes", "editedIndex", "value"],
  data() {
    return {
      items: CLAUSES.map(c => c.value),
      box: {},
      e1: 1,
      error: "",
      errored: false,
    };
  },
  beforeCreate() {
    this.$store.dispatch("configuration/retriveConfigurations");
  },
  computed: {
    ...mapState("documents", {
      markupType: "markupType",
      doc: "document",
      page: "currentPage"
    }),
    ...mapState('request', {
      currentRequest: "requestItem",
    }),
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    })
  },

  methods: {
    getClause(){
      let clauseSet = this.currentRequest.ClauseSet || this.currentRequest.request_type.clause_set;
      return this.configurations.filter( filter => filter.LookUpName === clauseSet ).map( item => {
        let arr = [];
        arr['text'] = this.$vuetify.lang.current === 'en' ? item.LookUpKey + " " + item.LookUpStringE : item.LookUpKey + " " + item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        return  arr;
      })
    },
    close() {
      if(this.editedIndex === -1) {
        this.e1 = 1;
        this.$emit('close')
      }
    },
    remove(item) {
      this.drawingBox.clausesUsed.splice(
          this.drawingBox.clausesUsed.map(i=>i.value).indexOf(item.value),
        1
      );
      this.drawingBox.clausesUsed = [...this.drawingBox.clausesUsed];
    },
    async save() {
      var boxList = [];
      if (this.markupType === "Document") {
        boxList = this.docBoxes;
        boxList.forEach(box => {
          (box.clausesUsed = this.drawingBox.clausesUsed.map(i=>i.value)),
            (box.rationale = this.drawingBox.rationale);
        });
      } else {
        boxList = [
          {
            id: this.drawingBox.id,
            top: this.drawingBox.top,
            left: this.drawingBox.left,
            height: this.drawingBox.height,
            width: this.drawingBox.width,
            color: this.drawingBox.color,
            pageNum: this.page,
            clausesUsed: this.drawingBox.clausesUsed.map(j => Array.isArray(j) ? j.value : j ),
            rationale: this.drawingBox.rationale
          }
        ];
        if(this.markupType === "Page") {
          const request = {
            id: this.doc.request_id,
            RequestStatus: 'Partial'
          }
          this.updateRequestStatus(request);
        }
      }
      if (this.editedIndex > -1) {
        this.updateBox(boxList);
        this.$emit('input');
        this.setIsDrawing(false);
      } else {
        this.addBox(boxList);
        this.$emit('input');
        this.setIsDrawing(false);
      }
      this.e1 = 1;
    },

    ...mapActions("documents", ["updateBox", "addBox", "setIsDrawing"]),

    ...mapActions('request', ['updateRequestStatus'])
  }
};
</script>