<template>
  <v-app-bar app color="#237076" dark>
    <v-app-bar-nav-icon @click="toggleSideBar()" class="order-1"></v-app-bar-nav-icon>
    <v-toolbar-title class="order-2">{{ $t('axcess1editor')}}</v-toolbar-title>
    <v-divider
      class="mx-4 order-3"
      inset
      vertical
    ></v-divider>
    <v-switch class="mt-6 order-4"
        v-model="docMode"
        :label="docMode ? docModeRedactText: docModeViewText"
    ></v-switch>
    <create-package class="order-5" />
    <v-spacer class="order-6" />


    <v-tooltip bottom v-if="document">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              v-on="on"
              @click="searchDialog = true"
              order="0"
              style="order:13"
          >
            <v-icon>mdi-file-find</v-icon>
          </v-btn>
        </template>
        <v-dialog v-model="searchDialog" max-width="800px">
            <v-card>
              <v-card-title class="subtitle-1">
                <span>{{ $t("search")}}</span>
                <v-text-field
                    v-model="searchText"
                    placeholder="Enter Keyword ..."
                    class="pl-2"
                ></v-text-field>
                <v-spacer></v-spacer>
                OCR {{ $t("search")}}
                <v-switch class="ma-2 pa-2" :hide-details=true v-model="searchBetween" align="center" style="vertical-align: middle;"></v-switch>
                {{ searchBetween ? "On" : "Off" }}
              </v-card-title>
              <hr>
              <v-list v-if="searchBetween">
                <v-card-text>
                  {{ $t("searchfor")}}: {{ocrText}}
                </v-card-text>
                <v-card-text v-if="process">
                  <label>{{$t("Page recognition progress")}}:</label>
                  <v-progress-linear
                      v-model="process"
                      height="25"
                  >
                    <strong>{{ Math.ceil(process) }}%</strong>
                  </v-progress-linear>
                </v-card-text>
                <v-list>
                  <v-list-item
                      v-for="(item, i) in searchResponse"
                      :key="i"
                      :disabled="item.disabled"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="'Page Number : ' + item.page_num"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      {{item.occurance}}
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-list>
              <v-list v-else>
                <v-list-item-group
                    active-class="blue--text"
                >
                  <v-list-item
                      v-for="(item, i) in searchResponse"
                      :key="i"
                      :disabled="item.disabled"
                      @click="changePage(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ $t('Page Number')}}:{{item.page_num}}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t('Document Name')}}: {{filterDocTitle(item.doc_id)}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      {{item.occurance}}
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="custom-color-accent-text" text @click="searchDialog = false, searchResponse = [], searchBetween = false, searchText = null,ocrText=null">{{$t('Close')}}</v-btn>
                <v-btn v-if="searchBetween" class="custom-color-accent-text" :disabled="! searchText.length" text @click="ocrData(searchText)">{{$t('scan')}}</v-btn>
                <v-btn v-else class="custom-color-accent-text" :disabled="! searchText.length" text @click="searchData(searchText)">{{$t('search')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      <span>{{ $t('Search in current document')}}</span>
    </v-tooltip>
    <v-tooltip bottom v-if="document">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text
               v-on="on"
               style="order:14"
               @click="historyDialog = true">
          <v-icon>mdi-calendar-clock-outline</v-icon>
        </v-btn>
      </template>
      <v-dialog v-model="historyDialog" max-width="900px">
        <v-card>
          <v-card-title>
            <span class="headline">{{$t('Version History')}}:   {{document.name}}</span>
          </v-card-title>
          <v-card-text>
            <v-data-table :mobile-breakpoint="1081" 
                :headers="headers"
                :items="prepareHistory()"
                sort-by="id"
                class="elevation-0"
                :footer-props="{
                  'items-per-page-text':$t('rowsperpage')
                }"
            >
              <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
              <template v-slot:item.id="{item}">
                <div>{{ "1."+item.id}}</div>
              </template>
              <template v-slot:item.action="{ item }" disabled="1">
                <v-btn
                    small
                    color="success"
                    @click="seePreview(item)"
                >
                  <v-icon left x-small>
                    mdi-eye-outline
                  </v-icon>
                  Preview
                </v-btn>
              </template>
              <template v-slot:item.created_at="{item}">
                <div>{{formatDate(item.created_at)}}</div>
              </template>
            </v-data-table>
          </v-card-text>
          <v-list>
            <v-list-item-group
                active-class="blue--text"
            >
              <v-list-item
                  v-for="(item, i) in searchResponse"
                  :key="i"
                  :disabled="item.disabled"
                  @click="changePage(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('Page Number')}} :{{item.page_num}}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('Document Name')}}: {{filterDocTitle(item.doc_id)}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  {{item.occurance}}
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-dialog>
      <span>{{ $t('Version History')}}</span>
    </v-tooltip>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text
               class="order-10"
               v-bind="attrs"
               v-on="on" >
          <v-icon>mdi-alert-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{$t('documentstatus')}}: {{ status }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{$t('pagestatus')}}: {{ page_status }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{$t('pagedisposition')}}: {{ page_disposition }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip bottom v-if="document && docMode">
      <template v-slot:activator="{ on }">
      <v-btn text  v-on="on"
             class="order-9"
             @click="addConsultants()" >
        <v-icon>mdi-account-box-outline</v-icon>
      </v-btn>
      </template>
      <span>{{ $t('Set Page for Consultation')}}</span>
    </v-tooltip>
    <v-menu v-model="slider_menu" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
                class="order-11"
                dark
                text
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
            >
              <v-icon>mdi-creation</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('Enhance Document Quality')}}</span>
        </v-tooltip>
      </template>
      <v-card style="width:200px !important;" class="pa-1 text-center">
          <v-subheader>{{ $t('Brighness')}}</v-subheader>
            <v-slider
                v-model="localEnhancement.brightness"
                :max="200"
                outline
                :min="0"
                class="align-center"
            >
              <template v-slot:append>
                <v-text-field
                    v-model="localEnhancement.brightness"
                    class="mt-0 pt-0"
                    type="number"
                    style="width: 60px"
                ></v-text-field>
              </template>
            </v-slider>
        <v-subheader>{{ $t('Contrast')}}</v-subheader>
        <v-slider
            v-model="localEnhancement.contrast"
            :max="200"
            outline
            :min="0"
            class="align-center"
        >
          <template v-slot:append>
            <v-text-field
                v-model="localEnhancement.contrast"
                class="mt-0 pt-0"
                type="number"
                style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>
        <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="slider_menu = false"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="updateEnhancement"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-menu>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          class="order-12"
          value="Document"
          text
          @click="getOriginalDoc()"
          v-on="on"
        >
          <v-icon>mdi-cloud-download</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('Download Original Document')}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          value="Document"
          class="order-13"
          text
          @click="setWithHeldDocument()"
          v-on="on"
          :disabled="!docMode"
          style="order:8"
        >
          <v-icon>mdi-file-settings</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('markadocument')}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn style="order:7" class="order-14" value="Page" text @click="setWithHeldPage()" v-on="on" :disabled="!docMode">
          <v-icon>mdi-file-document</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('markapage')}}</span>
    </v-tooltip>
    <v-tooltip bottom >
      <template v-slot:activator="{ on }">
        <v-btn style="order:15" class="order-6" value="Rectangles" text @click="setWithHeldRectangle()" v-on="on" :disabled="!docMode">
          <v-icon>mdi-rectangle-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('addredactionrectangle')}}</span>
    </v-tooltip>
    <v-tooltip bottom >
      <template v-slot:activator="{ on }">
        <v-btn style="order:15" class="order-6" value="Highlights" text @click="setWithHeldHighlights()" v-on="on" :disabled="!docMode">
          <v-icon>mdi-format-color-highlight</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('add highlights')}}</span>
    </v-tooltip>
    <v-dialog v-model="dialogConsult" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('Choose Consultant')}}</span>
        </v-card-title>
        <v-card-text>
            <v-combobox
                v-model="chips"
                :items="address"
                item-text="CompanyAgency"
                item-value="id"
                chips
                clearable
                :label="$t('consultationagencies')"
                multiple
                solo
                filled
                hide-details
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove(item)"
                >
                  <strong>{{ `${item.FirstName} ${item.LastName}` }}</strong>&nbsp;
                </v-chip>
              </template>

              <template v-slot:item="{ attrs, item, select, selected }">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ item.FirstName }} {{ item.LastName }} {{ item.CompanyAgency? "- "+ item.CompanyAgency:""}}
              </template>
            </v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-btn class="custom-color-accent-text" text @click="dialogConsult = false">{{$t('cancel')}}</v-btn>
          <v-btn class="custom-color-accent-text" text @click="send">{{$t('save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="document" style="order:16">
        <v-btn @click="prev()" icon :disabled="!docMode" >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-chip
            v-if="numPages!==0"
            class="ma-2"
            color="white"
            text-color="primary"
        >{{currentPage}} of {{numPages}}</v-chip>
        <v-btn icon :disabled="!docMode">
        <v-icon @click="next()">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CreatePackage from "../consultation/createPackage";
//import Tesseract from "tesseract.js"
import {createWorker} from 'tesseract.js';
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
import axios from "axios";

export default {
  components: {
    CreatePackage
  },
  watch:{
    mode(val){
      if(val === 'edit'){
        this.docMode = true;
      }else{
        this.docMode = false;
      }
    },
    docMode(val){
      this.setCurrentPage(1);
      if(val){
        this.setMode('edit');
      }else{
        this.setMode('view');
      }
    },
    document(){
      this.getLookup();
    },
    searchDialog(val){
      if( ! val ){
        this.searchText = '';
      }
    }
  },
  data(){
    return {
      slider_menu:false,
      localEnhancement:{
        brightness:50,
        contrast:50
      },
      searchBetween:false,
      docMode:false,
      docModeRedactText: this.$t('Redact Mode'), 
      docModeViewText: this.$t('Preview Mode'),
      drawer: null,
      status:"",
      page_status:"",
      page_disposition:"",
      dialogConsult:false,
      chips: [],
      findDoc:'',
      searchDialog:false,
      historyDialog:false,
      searchResponse:[],
      ocrResponse:[],
      ocrText:"",
      searchText:"",
      progress:'',
      progressStatus:"",
      process:0,
      headers: [
        {
          text: "Version No.",
          align: "left",
          sortable: true,
          value: "id"
        },
        { text: "Page No.", value: "page_num" },
        { text: "Clause", value: "clause" },
        { text: "Rationale", value: "rationale" },
        { text: "Created By", value: "created_by" },
        { text: "Updated By", value: "updated_by" },
        { text: "Date", value: "created_at" },
        { text: "", value: "action", sortable: false }
      ],
      getMarkups:[
        {id:1, page_num:1,clause:'Excluded - Exclu, Not rel - Non pert, P 19.(1), P 19.(1)(a)',rationale:"Exception Note", created_at:'2020-07-18 16:46:29',created_by:'Admin', updated_by:''},
        {id:2, page_num:2, clause:'Excluded - Exclu, P 12.(1), P 13 (1)',rationale:"Excluded", created_at:'2020-07-18 16:49:05',created_by:'Softsim', updated_by:''},
        {id:3, page_num:3, clause:'Duplicate - Copie, Not rel - Non pert',rationale:"Duplicate Copy", created_at:'2020-07-18 16:50:29',created_by:'Admin', updated_by:''},
        {id:4, page_num:1, clause:'P 13 (2)(f1),P 13 (2)(f),f',rationale:"Clause Applied", created_at:'2020-07-18 16:52:29',created_by:'Admin', updated_by:'Softsim'},
        {id:5, page_num:3, clause:'Excluded - Exclu, P 12.(1)',rationale:"Excluded", created_at:'2020-07-18 16:53:29',created_by:'Admin', updated_by:'Softsim'},
        {id:6, page_num:4, clause:'Not rel - Non pert',rationale:"Not Related Page", created_at:'2020-07-18 16:56:29',created_by:'Admin', updated_by:''},
      ]
    }
  },
  created() {
    //await this.$store.dispatch("consultation/retrieveConsultations");
    Object.assign( this.localEnhancement, this.enhancement );
  },
  mounted(){

    this.getLookup();
    if(this.pageStatus.page_status){
      this.configurations.filter( filter => filter.LookUpName === 'PageStatus' && filter.LookUpKey === this.pageStatus.page_status ).map( item => {
        this.page_status = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
      })
    }else{
      this.page_status = '';
    }
  },
  computed: {
    ...mapState("documents", {
      mode: "mode",
      id: "docId",
      numPages: "numPages",
      currentPage: "currentPage",
      document: "document",
      selectedDocs:"selectedDocs",
      pageStatus: "pageStatus",
      pagePdf: "pagePdf",
      docs: "docs",
      enhancement: "enhancement"
    }),

    ...mapState("request", {
      request: "requestItem"
    }),
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    }),
    ...mapState('createConsultation',{
      consultants: 'consultants'
    }),
    ...mapState("address", {
      address: "addresses"
    }),
  },
  methods: {
    updateEnhancement(){
      this.setEnhancement(this.localEnhancement);
      console.log(this.enhancement);
    },
    prepareHistory(){
      let history = [];
      if(this.document.layers[0] && this.document.layers[0].boxes){
        this.document.layers[0].boxes.forEach((i,j)=>{
          history.push({id:j, page_num:i.pageNum,clause:i.clausesUsed.join(', '),rationale:i.rationale, created_at:i.created_at,created_by:'SoftSim', updated_by:'SoftSim'})
        })
      }
      return history;
    },
    formatDate(value) {
      return value != null ? moment(value).format("YYYY-MM-DD HH:mm") :"";
    },
    seePreview(){
      alert('hi');
    },
    filterDocTitle(id){
      return this.docs.filter(i=>i.id === id).map(i=>i.name)[0];
    },
    changePage(item){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
      this.searchDialog = false;
      const preview = `${axios.defaults.baseURL}/document/file/${item.doc_id}`;
      this.setMode('view'); 
      this.setPreview(preview);
      setTimeout( ()=>{this.setCurrentPage(item.page_num)} ,1000);
    },
    async searchSensitive(){
      axcessApi.getRequest('/pagestatus/'+ this.request.id ).then(response => {
        let searchOccurance = [];
        let regExp = new RegExp("^(\\d{3}.\\d{3}.\\d{3})|(\\d{9})|(\\d{3}\\s\\d{3}\\s\\d{3})|(.\\d{3}\\s\\d{3}\\s\\d{3}.)",'gmi')
        if( response.data.length ){
          response.data.forEach(i => {
            if(i.page_content){ 
              let j = ( i.page_content.match(regExp) || [] ).length;
              if(j>0)
                searchOccurance.push( {page_num: i.page_num,doc_id: i.doc_id, occurance: j} );
            }
          })
        }
        this.searchResponse = searchOccurance ? searchOccurance : [];
      });
    },
    async searchData(searchtext){
      axcessApi.getRequest('/pagestatus/'+ this.request.id ).then(response => {
        let searchOccurance = [];
        let regExp = new RegExp(searchtext, 'gi');
        if( response.data.length ){
          response.data.forEach(i => {
            if(i.page_content){
              let j = ( i.page_content.match(regExp) || [] ).length;
              if(j>0)
              searchOccurance.push( {page_num: i.page_num,doc_id: i.doc_id, occurance: j} );
            }
          })
        }
        this.searchResponse = searchOccurance ? searchOccurance : [];
      });
    },
    ocrData(searchText){ 
      if( searchText ){
        let current = this;
        current.ocrText = searchText;
        current.process = 0;
        current.searchResponse = [];
        let viewport = this.pagePdf[this.currentPage-1].getViewport({ scale: (800/this.pagePdf[this.currentPage-1].view[2]) });
        let canvas = document.createElement('canvas');
        let ccontext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        let renderContext = {
          canvasContext: ccontext,
          viewport: viewport
        };
        let renderTask = this.pagePdf[this.currentPage-1].render(renderContext);
        renderTask.promise.then(async function () {
          let dataUrl = canvas.toDataURL();
          let regExp = new RegExp(searchText, 'g');
          let worker = createWorker({
            logger: m => {
              if( m.status == 'recognizing text'){
                current.process = (m.progress * 100).toFixed(2);
              }
            }
          })
          await worker.load();
          await worker.loadLanguage('eng');
          await worker.initialize('eng');
          await worker.setParameters({
            tessedit_ocr_engine_mode: 3
          });
          if (dataUrl) {
            const { data: { text } } = await worker.recognize(dataUrl );
            if (text) {
              let j = ( text.match(regExp) || [] ).length;
              current.searchResponse.push( {page_num: current.currentPage, occurance: j} );
            } else {
              current.searchResponse = [];
            }
            await worker.terminate();
          }
        })
      }

    },
    getOriginalDoc(){
         return new Promise((resolve, reject) => {
        axcessApi.getRequest('/originaldoc/' + this.document.request_id + '/docid/' + this.document.name,{responseType: 'arraybuffer'})
            .then(res => {
              let blob = new Blob([res.data], {type:'application/*'})
              let link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = this.document.name
              link._target = 'blank'
              link.click();
              resolve(res)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    send(){
       let data = {
        request_id : this.request.id,
        doc_id: this.id,
        page_status: 'C',
        page_num: this.currentPage,
        consultants: JSON.stringify(this.chips.map(item => item.id)),
        created_by: 1,
        updated_by: 1
      }
      axcessApi.postRequest('pagestatus',data );
      if(this.consultants.length){
        if( this.consultants.filter( item => item.doc.id === this.id ).length ){
          this.consultants.forEach( ( item, index) => {
            if( item.doc.id === this.id ) { 
              this.consultants[index].doc.id = this.id;
              if( this.consultants[index].doc.page.filter( i => i.id === this.currentPage ).length ){
                this.consultants[index].doc.page.forEach((j,k) => {
                  if( j.id === this.currentPage ){ 
                    this.consultants[index].doc.page[k] = {id : this.currentPage, consultant: this.chips.map(item => item.id)}
                  }
                })
              }else{
                this.consultants[index].doc.page.push( {id : this.currentPage, consultant: this.chips.map(item => item.id)} )
              }
              // this.consultants[index].doc.page.push()
              // this.consultants[index].doc.page[this.currentPage] = {id : this.currentPage, consultant: this.chips.map(item => item.id)};
            }
          });
        }else{
          let consultant = {
            doc: {
              id: this.id,
              page: [{
                id: this.currentPage,
                consultant: this.chips.map(item => item.id)
              }]
            }
          }
          this.consultants.push(consultant);
        }
      }else{
        let consultant = {};
        consultant = {doc:{id: this.id, page: [{id : this.currentPage, consultant: this.chips.map(item => item.id)}]}};
        // consultant.doc.page[index] = {id : this.currentPage, consultant: this.chips.map(item => item.id)};
        this.consultants.push(consultant);
      }

      this.setConsultants(this.consultants);
      this.dialogConsult = false;
    },
    remove (item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [...this.chips]
    },
    async addConsultants(){
      this.chips = [];
      if(this.pageStatus){
        if( this.pageStatus.consultants && JSON.parse( this.pageStatus.consultants ) ){
          this.chips = JSON.parse( this.pageStatus.consultants );
        }else{
          this.chips = [];
        }
      }else{
        this.chips = [];
      }
      if( this.chips.length ) this.chips = this.address.filter(item => this.chips.includes( item.id ) );
      else this.chips = [];
      this.dialogConsult = true;
    },
    getLookup() {
      if(this.configurations && this.document){
        this.configurations.filter( filter => filter.LookUpName === 'DocStatus' && filter.LookUpKey === this.document.status ).map( item => {
          this.status = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
        })
        this.configurations.filter( filter => filter.LookUpName === 'PageStatus' && filter.LookUpKey === this.pageStatus.page_status ).map( item => {
          this.page_status = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
        })

      }
    },
    prev() {
      if (this.currentPage > 1) {
        const page = this.currentPage - 1;
        this.setCurrentPage(page);
        this.setPageStatus({request_id: this.request.id, id: this.id, currentPage: page })
        setTimeout(()=> {if(this.pageStatus.page_status){
          this.configurations.filter( filter => filter.LookUpName === 'PageStatus' && filter.LookUpKey === this.pageStatus.page_status ).map( item => {
            this.page_status = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
          })
        }else{
          this.page_status = '';
        }}, 2000);
      }
    },

    next() {
      if (this.currentPage < this.numPages) {
        const page = this.currentPage + 1;
        this.setCurrentPage(page);
        this.setPageStatus({request_id: this.request.id, id: this.id, currentPage: page })
        setTimeout(()=> {if(this.pageStatus.page_status){
          this.configurations.filter( filter => filter.LookUpName === 'PageStatus' && filter.LookUpKey === this.pageStatus.page_status ).map( item => {
            this.page_status = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
          })
        }else{
          this.page_status = '';
        }}, 2000);

      }
    },

    print() {
      this.$root.$emit("component1");
    },

    saveDoc() {
      this.$root.$emit("saveDocument");
    },

    setWithHeldPage() {
      this.$root.$emit("PDFEditModePage");
    },

    setWithHeldDocument() {
      this.$root.$emit("PDFEditModeDocument");
    },

    setWithHeldRectangle() {
      this.$root.$emit("PDFEditModeRectangle");
    },
    setWithHeldHighlights() {
      this.$root.$emit("PDFEditModeHighlights");
    },

    toggleSideBar() {
      this.$root.$emit("toggleSideDrawer");
    },

    ...mapActions("documents", [
      "setPreview",
      "setMode",
      "setCurrentPage",
      "toggleDrawer",
      "setBoxColor",
      "setExtension",
      "setPDFPage",
      "setNumPages",
      "savePDF",
        "setPageStatus",
        "getScale",
        "setPreview",
        "setEnhancement"
    ]),
    ...mapActions("createConsultation",['setConsultants'])
  },

};
</script>