<template>
  <div>
    <v-list-item>
      <v-list-item-title class="pointer" @click.stop="openPCO = true">PCO Schedule</v-list-item-title>
    </v-list-item>
    <v-dialog v-model="openPCO" relative>
      <div class="mx-2 my-1">
        <v-btn color="red lighten-1" fab x-small dark @click.stop="openPCO=false" top right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <PCOConsultationTable :request="request"></PCOConsultationTable>
    </v-dialog>
  </div>
</template>

<script>
import PCOConsultationTable from "@/components/consultation/PCOConsultationTable";
import { mapActions } from "vuex";
export default {
  name: "pcoconsultation",
  props:['request'],
  components:{
    PCOConsultationTable
  },
  created() {
    this.retrieveConsultations();
  },
  data() {
    return {
      openPCO: false
    }
  },
  methods:{
    ...mapActions('consultation',['retrieveConsultations'])
  }
}
</script>

<style scoped>
.pointer{
  cursor:pointer;
}
</style>